import Cookies from 'js-cookie'

function trackAffiliate() {
  if (Cookies.get('click-ref-co')) {
    return
  }

  const accepted = {
    'app.businessmadesimple.com': ['/', '/trial', '/daily/sign-up', '/sign-up'],
    'businessmadesimple.com': ['/', '/daily'],
    'livestream.businessmadesimple.com': ['/'],
    'mybusinessreport.com': ['/', '/assessment'],
    'storybrand.com': ['/', '/live'],
    'storybrandmarketingreport.com': ['/', '/assessment']
  }

  const matchHost = (host, endpoint) => {
    if (!accepted[host]) {
      return false
    }

    if (accepted[host].find(r => r === endpoint)) {
      return true
    }

    return false
  }

  const url = new URL(window.location.href)
  const search = new URLSearchParams(url.search)
  const affiliate = search.get('ref') || search.get('referral_code') || null
  const hostname = url.hostname
  const endpoint = url.pathname.length > 1 ? url.pathname.replace(/\/$/, '') : url.pathname
  const referrer = document.referrer

  if (!affiliate || !matchHost(hostname, endpoint)) {
    return
  }

  let data = {}
  data.url = hostname + endpoint
  data.affiliate_ref = affiliate
  data.referrer = referrer

  fetch('https://protagonist-prod.herokuapp.com/clicks', {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (res.ok) {
      Cookies.set('click-ref-co', 1);
    }
  }).catch(() => {
    throw new Error('Issue tracking affiliate link.');
  })
}

window.addEventListener('load', trackAffiliate)
